import React from "react"
import "./traject.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Prices from "../components/prices"

const SecondPage = () => (
  <Layout>
    <SEO
      title="Persoonlijke ontwikkeling"
      description="Door drijveren-analyses, systemische opstellingen en stressanalyses werkt de gepatenteerde Zipperr® methode aan persoonlijke ontwikkeling en zakelijke groei"
    />
    <div className="containerPad bgalt">
      <h1 style={{ textAlign: "center" }}>
        Kies voor een Zipperr® traject voor persoonlijke ontwikkeling en
        zakelijke groei
      </h1>
      <div className="sidebysidez">
        <p>
          Met de Zipperr®Methode verhoog je de mentale veerkracht in je
          organisatie Wij begeleiden werkgevers en werknemers op het gebied van
          mentale gezondheid, ook wel mentale veerkracht genoemd. Hoe houd of
          krijg je medewerkers mentaal fit. Daarmee voorkom je ziekteverzuim en
          verhoog je de productiviteit. Dit is een win-winsituatie voor zowel de
          organisatie als de werknemer. Wij werken samen met het grootste
          EHealth platform van Europa en hebben een effectieve en snelwerkende
          methode ontwikkeld die de mentale gezondheid van de mensen binnen uw
          organisatie kan meten, monitoren (mentale veerkracht monitor) en
          managen. De Zipperr®methode biedt de organisatie en des medewerkers de
          hulp en ondersteuning. die juist deze tijd zo verlangt.
        </p>

        <p>
          Met de Zipperr®Methode verhoog je de mentale veerkracht in je
          organisatie Wij begeleiden werkgevers en werknemers op het gebied van
          mentale gezondheid, ook wel mentale veerkracht genoemd. Hoe houd of
          krijg je medewerkers mentaal fit. Daarmee voorkom je ziekteverzuim en
          verhoog je de productiviteit. Dit is een win-winsituatie voor zowel de
          organisatie als de werknemer. Wij werken samen met het grootste
          eHealth platform van Europa en hebben een effectieve en snelwerkende
          methode ontwikkeld die de mentale veerkracht van de mensen binnen uw
          organisatie kan meten, monitoren (mentale veerkracht monitor) en
          managen. De Zipperr®methode biedt de organisatie en des medewerkers de
          hulp en ondersteuning. die juist deze tijd zo verlangt. Onderdeel van
          deze methode is een nulmeting, een scan bij aanvang zodat je een goed
          actueel beeld krijgt van de (mentale) veerkracht van jouw medewerkers.
          Op basis van deze scan worden er online eHealth modules aangeboden,
          toegesneden op ieders persoonlijke situatie. Bij meer acute
          problematiek kan er gebruik gemaakt worden van persoonlijke
          interventies op weg naar mentale veerkracht en duurzame inzetbaarheid.
          Tot slot zal er ook een eindmeting verricht worden, waarbij de
          resultaten van het traject inzichtelijk gemaakt worden
        </p>
      </div>
      <Prices />
    </div>
  </Layout>
)

export default SecondPage
